import type { FC } from 'react';
import Users03Icon from '@untitled-ui/icons-react/build/esm/Users03';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { ContactsPopover } from './contacts-popover';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const now = new Date();

interface Contact {
  id: string;
  avatar: string;
  isActive: boolean;
  lastActivity?: number;
  name: string;
  email: string;
}

const useContacts = (): Contact[] => {
  return [
    {
      id: '5e8891ab188cd2855e6029b7',
      avatar: '/assets/consultants/andreas.png',
      isActive: true,
      lastActivity: now.getTime(),
      name: 'Andreas Fischer',
      email: 'Andreas Fischer <anfi@bilhandel.dk>',
    },
    {
      id: '5e887a62195cc5aef7e8ca5d',
      avatar: '/assets/consultants/dorthe.png',
      isActive: true,
      lastActivity: now.getTime(),
      name: 'Dorthe Abild Thomsen',
      email: 'Dorthe Thomsen <dt@bilhandel.dk>',
    },
    {
      id: '5e887ac47eed253091be10cb',
      avatar: '/assets/consultants/joakim.png',
      isActive: true,
      lastActivity: now.getTime(),
      name: 'Joakim Madsen',
      email: 'Joakim Madsen <jm@bilhandel.dk>',
    },
    {
      id: '5e887b209c28ac3dd97f6db5',
      avatar: '/assets/consultants/jonas.png',
      isActive: true,
      lastActivity: now.getTime(),
      name: 'Jonas Alexandersen',
      email: 'Jonas Holmstrøm Alexandersen <jha@bilhandel.dk>',
    },
  ];
};

export const ContactsButton: FC = () => {
  const popover = usePopover<HTMLButtonElement>();
  const contacts = useContacts();

  return (
    <>
      <Tooltip title="Konsulenter">
        <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
          <SvgIcon>
            <SupportAgentIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <ContactsPopover
        anchorEl={popover.anchorRef.current}
        contacts={contacts}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
